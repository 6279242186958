.introjs-overlay {
  position: absolute;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  z-index: 999999;
  opacity: 0;
  -webkit-transition: all .3s ease-out;
  -o-transition: all .3s ease-out;
  transition: all .3s ease-out
}

.introjs-showElement {
  z-index: 9999999 !important
}

tr.introjs-showElement>td {
  z-index: 9999999 !important;
  position: relative
}

tr.introjs-showElement>th {
  z-index: 9999999 !important;
  position: relative
}

.introjs-disableInteraction {
  z-index: 99999999 !important;
  position: absolute;
  background-color: #fff;
  opacity: 0
}

.introjs-relativePosition {
  position: relative
}

.introjs-helperLayer {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  position: absolute;
  z-index: 9999998;
  border-radius: 4px;
  -webkit-transition: all .3s ease-out;
  -o-transition: all .3s ease-out;
  transition: all .3s ease-out
}

.introjs-helperLayer * {
  -webkit-box-sizing: content-box;
  box-sizing: content-box
}

.introjs-helperLayer :before {
  -webkit-box-sizing: content-box;
  box-sizing: content-box
}

.introjs-helperLayer :after {
  -webkit-box-sizing: content-box;
  box-sizing: content-box
}

.introjs-tooltipReferenceLayer {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  position: absolute;
  visibility: hidden;
  z-index: 100000000;
  background-color: transparent;
  -webkit-transition: all .3s ease-out;
  -o-transition: all .3s ease-out;
  transition: all .3s ease-out
}

.introjs-helperNumberLayer {
  color: #9e9e9e;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px
}

.introjs-arrow {
  border: 5px solid transparent;
  content: "";
  position: absolute
}

.introjs-arrow.top {
  top: -10px;
  left: 10px;
  border-bottom-color: #fff
}

.introjs-arrow.top-right {
  top: -10px;
  right: 10px;
  border-bottom-color: #fff
}

.introjs-arrow.top-middle {
  top: -10px;
  left: 50%;
  margin-left: -5px;
  border-bottom-color: #fff
}

.introjs-arrow.right {
  right: -10px;
  top: 10px;
  border-left-color: #fff
}

.introjs-arrow.right-bottom {
  bottom: 10px;
  right: -10px;
  border-left-color: #fff
}

.introjs-arrow.bottom {
  bottom: -10px;
  left: 10px;
  border-top-color: #fff
}

.introjs-arrow.bottom-right {
  bottom: -10px;
  right: 10px;
  border-top-color: #fff
}

.introjs-arrow.bottom-middle {
  bottom: -10px;
  left: 50%;
  margin-left: -5px;
  border-top-color: #fff
}

.introjs-arrow.left {
  left: -10px;
  top: 10px;
  border-right-color: #fff
}

.introjs-arrow.left-bottom {
  left: -10px;
  bottom: 10px;
  border-right-color: #fff
}

.introjs-tooltip {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  position: absolute;
  visibility: visible;
  background-color: #fff;
  /* min-width: 400px;
  max-width: 600px; */
  width: 440px;
  border-radius: 5px;
  -webkit-box-shadow: 0 3px 30px rgba(33, 33, 33, .3);
  box-shadow: 0 3px 30px rgba(33, 33, 33, .3);
  -webkit-transition: opacity .1s ease-out;
  -o-transition: opacity .1s ease-out;
  transition: opacity .1s ease-out
}

.introjs-bottom-left-aligned {
  margin-left: 350px;
}

/* .introjs-floating {
  margin-top: -200px !important;
  margin-left: -200px !important;
} */

.introjs-tooltiptext {
  padding: 20px;
  width: max-content;
  max-width: 400px;
  font-size: 14.5px;
  white-space: pre-wrap;
}

.introjs-dontShowAgain {
  padding-left: 20px;
  padding-right: 20px
}

.introjs-dontShowAgain input {
  padding: 0;
  margin: 0;
  margin-bottom: 2px;
  display: inline;
  width: 10px;
  height: 10px
}

.introjs-dontShowAgain label {
  font-size: 14px;
  display: inline-block;
  font-weight: 400;
  display: inline-block;
  margin: 0 0 0 5px;
  padding: 0;
  background-color: #fff;
  color: #616161;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.introjs-tooltip-title {
  font-size: 18px;
  margin: 0;
  padding: 0;
  font-weight: 700;
  float: left;
  line-height: 32px
}

.introjs-tooltip-header {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px
}

.introjs-tooltip-header:after {
  content: ".";
  visibility: hidden;
  display: block;
  height: 0;
  clear: both
}

.introjs-tooltipbuttons {
  border-top: 1px solid #e0e0e0;
  padding: 20px;
  display: flex;
  white-space: nowrap;
  justify-content: space-between;
}

/* .introjs-tooltipbuttons:after {
  content: "";
  visibility: hidden;
  display: block;
  height: 0;
  clear: both
} */

#introjs_skip_button {
  background-color: #fff;
  color:#EE0D57
}

#introjs_skip_button:hover{
  background-color: #fff3f3;
  color:#EE0D57
}

.introjs-button {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  position: relative;
  overflow: visible;
  padding: .5rem 1rem;
  text-decoration: none;
  font-size: 14px;
  color: #fff;
  white-space: nowrap;
  cursor: pointer;
  background-color: #003c8f;
  border-radius: .2em;
  zoom: 1;
  display: inline-block;
  text-transform: uppercase;
}

.introjs-button:hover {
  background-color: #487bc4;
}

/* .introjs-button:focus {
  outline: 0;
  text-decoration: none;
  background-color: #eee;
  -webkit-box-shadow: 0 0 0 .2rem rgba(158, 158, 158, .5);
  box-shadow: 0 0 0 .2rem rgba(158, 158, 158, .5);
  border: 1px solid #616161;
  color: #212121
}

.introjs-button:active {
  outline: 0;
  text-decoration: none;
  background-color: #e0e0e0;
  border-color: #9e9e9e;
  color: #212121
} */

/* .introjs-button::-moz-focus-inner {
  padding: 0;
  border: 0
} */

.introjs-prevbutton {
  margin-right: 16px;
  background-color: #fff;
  color:#888
}

.introjs-prevbutton:hover {
  background-color: #eee;
}

.introjs-skipbutton {
  display: none;
  /* -webkit-box-sizing: content-box;
  box-sizing: content-box;
  color: #616161;
  font-size: 20px;
  cursor: pointer;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  padding: 7px 10px */
}

/* .introjs-skipbutton:focus,
.introjs-skipbutton:hover {
  color: #212121;
  outline: 0;
  text-decoration: none
} */


.introjs-disabled {
  color: #9e9e9e;
  border-color: #bdbdbd;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: default;
  background-color: #f4f4f4;
  background-image: none;
  text-decoration: none
}

.introjs-disabled:focus,
.introjs-disabled:hover {
  color: #9e9e9e;
  border-color: #bdbdbd;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: default;
  background-color: #f4f4f4;
  background-image: none;
  text-decoration: none
}

.introjs-hidden {
  display: none
}

.introjs-bullets {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px
}

.introjs-bullets ul {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  clear: both;
  margin: 0 auto 0;
  padding: 0;
  display: inline-block
}

.introjs-bullets ul li {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  list-style: none;
  float: left;
  margin: 0 2px
}

.introjs-bullets ul li a {
  -webkit-transition: width .1s ease-in;
  -o-transition: width .1s ease-in;
  transition: width .1s ease-in;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  display: block;
  width: 6px;
  height: 6px;
  background: #ccc;
  border-radius: 10px;
  text-decoration: none;
  cursor: pointer
}

.introjs-bullets ul li a:focus,
.introjs-bullets ul li a:hover {
  width: 15px;
  background: #999;
  text-decoration: none;
  outline: 0
}

.introjs-bullets ul li a.active {
  width: 15px;
  background: #999
}

.introjs-progress {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  overflow: hidden;
  height: 10px;
  margin: 10px;
  border-radius: 4px;
  background-color: #e0e0e0
}

.introjs-progressbar {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  float: left;
  width: 0%;
  height: 100%;
  font-size: 10px;
  line-height: 10px;
  text-align: center;
  background-color: #08c
}

.introjsFloatingElement {
  position: absolute;
  height: 0;
  width: 0;
  left: 50%;
  top: 50%
}

.introjs-fixedTooltip {
  position: fixed
}

.introjs-hint {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  position: absolute;
  background: 0 0;
  width: 20px;
  height: 15px;
  cursor: pointer
}

.introjs-hint:focus {
  border: 0;
  outline: 0
}

.introjs-hint:hover>.introjs-hint-pulse {
  background-color: rgba(60, 60, 60, .57)
}

.introjs-hidehint {
  display: none
}

.introjs-fixedhint {
  position: fixed
}

@-webkit-keyframes introjspulse {
  0% {
    -webkit-transform: scale(.95);
    transform: scale(.95);
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, .7);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, .7)
  }

  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-box-shadow: 0 0 0 10px transparent;
    box-shadow: 0 0 0 10px transparent
  }

  100% {
    -webkit-transform: scale(.95);
    transform: scale(.95);
    -webkit-box-shadow: 0 0 0 0 transparent;
    box-shadow: 0 0 0 0 transparent
  }
}

@keyframes introjspulse {
  0% {
    -webkit-transform: scale(.95);
    transform: scale(.95);
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, .7);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, .7)
  }

  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-box-shadow: 0 0 0 10px transparent;
    box-shadow: 0 0 0 10px transparent
  }

  100% {
    -webkit-transform: scale(.95);
    transform: scale(.95);
    -webkit-box-shadow: 0 0 0 0 transparent;
    box-shadow: 0 0 0 0 transparent
  }
}

.introjs-hint-pulse {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  width: 15px;
  height: 15px;
  border-radius: 30px;
  background-color: rgba(136, 136, 136, .24);
  z-index: 10;
  position: absolute;
  -webkit-transition: all .2s ease-out;
  -o-transition: all .2s ease-out;
  transition: all .2s ease-out;
  -webkit-animation: introjspulse 2s infinite;
  animation: introjspulse 2s infinite
}

.introjs-hint-no-anim .introjs-hint-pulse {
  -webkit-animation: none;
  animation: none
}

.introjs-hint-dot {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  background: 0 0;
  border-radius: 60px;
  height: 50px;
  width: 50px;
  position: absolute;
  top: -18px;
  left: -18px;
  z-index: 1;
  opacity: 0
}

/*# sourceMappingURL=introjs.css.map */