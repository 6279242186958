.overflow-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space:  nowrap;
}

.react-flow__node-KSwimLane {
  z-index: -1 !important;
  visibility: visible !important;
  pointer-events: none !important;
  opacity: 0.5;
}

.k-swim-lane-clickable {
  pointer-events: auto !important;
  cursor:cell !important;
}

.k-swim-lane-hoverable {
  pointer-events: auto !important;
  cursor:default !important;
}

.react-flow__attribution {
  display: none;
}

.react-flow__node-KGroup {
  z-index: -1 !important;
}

.react-flow__node-KButtonNode {
  z-index: -1 !important;
}

.react-flow-show-edge-below-node .react-flow .react-flow__edges{
  z-index: -1 !important;
}

.react-flow-show-edge-below-node .react-flow__node-KGroup {
  z-index: 0 !important;
}

.react-flow__minimap{
  bottom:auto;
  top:20px;
  width: 160px;
  height:120px;
}

.react-flow__pane {
  cursor: grab;
}